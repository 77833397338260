import { gql_request, GqlRequestOptions } from 'util/request';
import * as types from './types';

export function createUploadReviewImageLink(variable: types.CreateUploadReviewImageLinkVariables, options?: GqlRequestOptions) {
  const query = 'mutation CreateUploadReviewImageLink($input: CreateUploadLinkInput!) { createUploadLink(input: $input) { acl url key_prefix fields } }';
  return gql_request<types.CreateUploadReviewImageLink, types.CreateUploadReviewImageLinkVariables>('CreateUploadReviewImageLink', query, variable, { use_consumer: true, ...options });
}

export function getSavedShopIdList(variable: types.GetSavedShopIdListVariables, options?: GqlRequestOptions) {
  const query = 'query GetSavedShopIdList($id_list: [String!]!) { saved_shop_id_list(id_list: $id_list) { shop_id_list } }';
  return gql_request<types.GetSavedShopIdList, types.GetSavedShopIdListVariables>('GetSavedShopIdList', query, variable, { use_consumer: true, ...options });
}

export function getSavedShopStatus(variable: types.GetSavedShopStatusVariables, options?: GqlRequestOptions) {
  const query = 'query GetSavedShopStatus($shop_id: ID!) { get_saved_shop_status(shop_id: $shop_id) }';
  return gql_request<types.GetSavedShopStatus, types.GetSavedShopStatusVariables>('GetSavedShopStatus', query, variable, { use_consumer: true, ...options });
}

export function saveUmdShop(variable: types.SaveUmdShopVariables, options?: GqlRequestOptions) {
  const query = 'mutation SaveUmdShop($shop_id: String!) { saveUmdShop(shop_id: $shop_id) { shop { id shop_main_domain } } }';
  return gql_request<types.SaveUmdShop, types.SaveUmdShopVariables>('SaveUmdShop', query, variable, { use_consumer: true, ...options });
}

export function removeUmdSavedShop(variable: types.RemoveUmdSavedShopVariables, options?: GqlRequestOptions) {
  const query = 'mutation RemoveUmdSavedShop($shop_id: String!) { removeUmdSavedShop(shop_id: $shop_id) }';
  return gql_request<types.RemoveUmdSavedShop, types.RemoveUmdSavedShopVariables>('RemoveUmdSavedShop', query, variable, { use_consumer: true, ...options });
}

export function getSavedProductIdList(variable: types.GetSavedProductIdListVariables, options?: GqlRequestOptions) {
  const query = 'query GetSavedProductIdList($id_list: [String!]!) { saved_product_id_list(id_list: $id_list) { catalog_product_id_list } }';
  return gql_request<types.GetSavedProductIdList, types.GetSavedProductIdListVariables>('GetSavedProductIdList', query, variable, { use_consumer: true, ...options });
}

export function getUserUUID(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserUUID { user { uuid } }';
  return gql_request<types.GetUserUUID, null>('GetUserUUID', query, variable, { use_consumer: true, ...options });
}

export function getUserAccountKakaoConnected(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserAccountKakaoConnected { user_account { kakao_connected } }';
  return gql_request<types.GetUserAccountKakaoConnected, null>('GetUserAccountKakaoConnected', query, variable, { use_consumer: true, ...options });
}

export function getAppleLoginConfig(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetAppleLoginConfig { getAppleLoginConfig { bundle_service_id nonce } }';
  return gql_request<types.GetAppleLoginConfig, null>('GetAppleLoginConfig', query, variable, { use_consumer: true, ...options });
}

export function loginForWeb(variable: types.LoginForWebVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginForWeb($input: LoginInput!) { login(input: $input) { success full_name uuid error_code error_message } }';
  return gql_request<types.LoginForWeb, types.LoginForWebVariables>('LoginForWeb', query, variable, { use_consumer: true, ...options });
}

export function requestMobileAuthentication(variable: types.RequestMobileAuthenticationVariables, options?: GqlRequestOptions) {
  const query = 'mutation RequestMobileAuthentication($input: RequestMobileAuthenticationInput!) { requestMobileAuthentication(input: $input) { result } }';
  return gql_request<types.RequestMobileAuthentication, types.RequestMobileAuthenticationVariables>('RequestMobileAuthentication', query, variable, { use_consumer: true, ...options });
}

export function verifyMobileAuthenticationToken(variable: types.VerifyMobileAuthenticationTokenVariables, options?: GqlRequestOptions) {
  const query = 'mutation VerifyMobileAuthenticationToken($input: VerifyMobileAuthenticationTokenInput!) { verifyMobileAuthenticationToken(input: $input) { email_list social_connection_list { email kakao_connected apple_connected google_connected facebook_connected toss_connected init_type has_password } } }';
  return gql_request<types.VerifyMobileAuthenticationToken, types.VerifyMobileAuthenticationTokenVariables>('VerifyMobileAuthenticationToken', query, variable, { use_consumer: true, ...options });
}

export function loginKakao(variable: types.LoginKakaoVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginKakao($input: LoginKakaoInput!) { loginKakao(input: $input) { uuid email full_name is_created message user_account { kakao_account { plus_friends_status } } } }';
  return gql_request<types.LoginKakao, types.LoginKakaoVariables>('LoginKakao', query, variable, { use_consumer: true, ...options });
}

export function loginFacebook(variable: types.LoginFacebookVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginFacebook($input: LoginFacebookInput!) { loginFacebook(input: $input) { user_account { uuid email full_name } } }';
  return gql_request<types.LoginFacebook, types.LoginFacebookVariables>('LoginFacebook', query, variable, { use_consumer: true, ...options });
}

export function loginApple(variable: types.LoginAppleVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginApple($input: LoginAppleInput!) { loginApple(input: $input) { user_account { uuid email full_name } } }';
  return gql_request<types.LoginApple, types.LoginAppleVariables>('LoginApple', query, variable, { use_consumer: true, ...options });
}

export function loginGoogle(variable: types.LoginGoogleVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginGoogle($input: LoginGoogleInput!) { loginGoogle(input: $input) { user_account { uuid email full_name } } }';
  return gql_request<types.LoginGoogle, types.LoginGoogleVariables>('LoginGoogle', query, variable, { use_consumer: true, ...options });
}

export function loginToss(variable: types.LoginTossVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginToss($input: LoginTossInput!) { loginToss(input: $input) { is_created user_account { uuid email full_name } } }';
  return gql_request<types.LoginToss, types.LoginTossVariables>('LoginToss', query, variable, { use_consumer: true, ...options });
}

export function signUpEmail(variable: types.SignUpEmailVariables, options?: GqlRequestOptions) {
  const query = 'mutation SignUpEmail($input: SignupInput!) { signup(input: $input) { user_account { email full_name mobile_tel uuid } message } }';
  return gql_request<types.SignUpEmail, types.SignUpEmailVariables>('SignUpEmail', query, variable, { use_consumer: true, ...options });
}

export function signUpFacebook(variable: types.SignUpFacebookVariables, options?: GqlRequestOptions) {
  const query = 'mutation SignUpFacebook($input: SignupFacebookInput!) { signupFacebook(input: $input) { user_account { email uuid } message } }';
  return gql_request<types.SignUpFacebook, types.SignUpFacebookVariables>('SignUpFacebook', query, variable, { use_consumer: true, ...options });
}

export function signUpApple(variable: types.SignUpAppleVariables, options?: GqlRequestOptions) {
  const query = 'mutation SignUpApple($input: SignupAppleInput!) { signupApple(input: $input) { user_account { email uuid } message } }';
  return gql_request<types.SignUpApple, types.SignUpAppleVariables>('SignUpApple', query, variable, { use_consumer: true, ...options });
}

export function signUpGoogle(variable: types.SignUpGoogleVariables, options?: GqlRequestOptions) {
  const query = 'mutation SignUpGoogle($input: SignupGoogleInput!) { signupGoogle(input: $input) { user_account { email uuid } message } }';
  return gql_request<types.SignUpGoogle, types.SignUpGoogleVariables>('SignUpGoogle', query, variable, { use_consumer: true, ...options });
}

export function signUpKakao(variable: types.SignUpKakaoVariables, options?: GqlRequestOptions) {
  const query = 'mutation SignUpKakao($input: SignupKakaoInput!) { signupKakao(input: $input) { user_account { email uuid kakao_account { plus_friends_status } } message } }';
  return gql_request<types.SignUpKakao, types.SignUpKakaoVariables>('SignUpKakao', query, variable, { use_consumer: true, ...options });
}

export function signUpToss(variable: types.SignUpTossVariables, options?: GqlRequestOptions) {
  const query = 'mutation SignUpToss($input: SignupTossInput!) { signupToss(input: $input) { user_account { email uuid } message } }';
  return gql_request<types.SignUpToss, types.SignUpTossVariables>('SignUpToss', query, variable, { use_consumer: true, ...options });
}

export function requestUserAccountPasswordReset(variable: types.RequestUserAccountPasswordResetVariables, options?: GqlRequestOptions) {
  const query = 'mutation RequestUserAccountPasswordReset($input: RequestUserAccountPasswordResetInput!) { requestUserAccountPasswordReset(input: $input) }';
  return gql_request<types.RequestUserAccountPasswordReset, types.RequestUserAccountPasswordResetVariables>('RequestUserAccountPasswordReset', query, variable, { use_consumer: true, ...options });
}

export function getUserAccount(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserAccount { user_account { email full_name } }';
  return gql_request<types.GetUserAccount, null>('GetUserAccount', query, variable, { use_consumer: true, ...options });
}

export function getUserProfile(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserProfile { user_account { email full_name mobile_tel authenticated } }';
  return gql_request<types.GetUserProfile, null>('GetUserProfile', query, variable, { use_consumer: true, ...options });
}

export function getDisappearingBenefits(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetDisappearingBenefits { disappearing_benefits { display_name count_of_coupons amount_of_points count_of_saved_products count_of_saved_shops } }';
  return gql_request<types.GetDisappearingBenefits, null>('GetDisappearingBenefits', query, variable, { use_consumer: true, ...options });
}

export function connectKakaoWithAuth(variable: types.ConnectKakaoWithAuthVariables, options?: GqlRequestOptions) {
  const query = 'fragment GetSimpleProfileFragment on UserAccount { uuid email full_name } mutation ConnectKakaoWithAuth($input: ConnectKakaoWithAuthInput!) { connectKakaoWithAuth(input: $input) { ...GetSimpleProfileFragment } }';
  return gql_request<types.ConnectKakaoWithAuth, types.ConnectKakaoWithAuthVariables>('ConnectKakaoWithAuth', query, variable, { use_consumer: true, ...options });
}

export function connectFacebookWithAuth(variable: types.ConnectFacebookWithAuthVariables, options?: GqlRequestOptions) {
  const query = 'fragment GetSimpleProfileFragment on UserAccount { uuid email full_name } mutation ConnectFacebookWithAuth($input: ConnectFacebookWithAuthInput!) { connectFacebookWithAuth(input: $input) { ...GetSimpleProfileFragment } }';
  return gql_request<types.ConnectFacebookWithAuth, types.ConnectFacebookWithAuthVariables>('ConnectFacebookWithAuth', query, variable, { use_consumer: true, ...options });
}

export function connectGoogleWithAuth(variable: types.ConnectGoogleWithAuthVariables, options?: GqlRequestOptions) {
  const query = 'fragment GetSimpleProfileFragment on UserAccount { uuid email full_name } mutation ConnectGoogleWithAuth($input: ConnectGoogleWithAuthInput!) { connectGoogleWithAuth(input: $input) { ...GetSimpleProfileFragment } }';
  return gql_request<types.ConnectGoogleWithAuth, types.ConnectGoogleWithAuthVariables>('ConnectGoogleWithAuth', query, variable, { use_consumer: true, ...options });
}

export function connectAppleWithAuth(variable: types.ConnectAppleWithAuthVariables, options?: GqlRequestOptions) {
  const query = 'fragment GetSimpleProfileFragment on UserAccount { uuid email full_name } mutation ConnectAppleWithAuth($input: ConnectAppleWithAuthInput!) { connectAppleWithAuth(input: $input) { ...GetSimpleProfileFragment } }';
  return gql_request<types.ConnectAppleWithAuth, types.ConnectAppleWithAuthVariables>('ConnectAppleWithAuth', query, variable, { use_consumer: true, ...options });
}

export function connectTossWithAuth(variable: types.ConnectTossWithAuthVariables, options?: GqlRequestOptions) {
  const query = 'fragment GetSimpleProfileFragment on UserAccount { uuid email full_name } mutation ConnectTossWithAuth($input: ConnectTossWithAuthInput!) { connectTossWithAuth(input: $input) { ...GetSimpleProfileFragment } }';
  return gql_request<types.ConnectTossWithAuth, types.ConnectTossWithAuthVariables>('ConnectTossWithAuth', query, variable, { use_consumer: true, ...options });
}

export function findUserAccountEmailByMobileTel(variable: types.FindUserAccountEmailByMobileTelVariables, options?: GqlRequestOptions) {
  const query = 'query FindUserAccountEmailByMobileTel($mobile_tel: String!) { findUserAccountEmailByMobileTel(mobile_tel: $mobile_tel) }';
  return gql_request<types.FindUserAccountEmailByMobileTel, types.FindUserAccountEmailByMobileTelVariables>('FindUserAccountEmailByMobileTel', query, variable, { use_consumer: true, ...options });
}

export function updateUserAccount(variable: types.UpdateUserAccountVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateUserAccount($input: UpdateUserAccountInput!) { updateUserAccount(input: $input) }';
  return gql_request<types.UpdateUserAccount, types.UpdateUserAccountVariables>('UpdateUserAccount', query, variable, { use_consumer: true, ...options });
}

export function deleteUserAccount(variable: types.DeleteUserAccountVariables, options?: GqlRequestOptions) {
  const query = 'mutation DeleteUserAccount($input: DeleteUserAccountInput!) { deleteUserAccount(input: $input) { success } }';
  return gql_request<types.DeleteUserAccount, types.DeleteUserAccountVariables>('DeleteUserAccount', query, variable, { use_consumer: true, ...options });
}

export function isLoggedIn(variable?: null, options?: GqlRequestOptions) {
  const query = 'query IsLoggedIn { isLoggedIn }';
  return gql_request<types.IsLoggedIn, null>('IsLoggedIn', query, variable, { use_consumer: true, ...options });
}

export function recertificationKakao(variable: types.RecertificationKakaoVariables, options?: GqlRequestOptions) {
  const query = 'mutation RecertificationKakao($input: RecertificationSocialInput!) { recertificationKakao(input: $input) { result } }';
  return gql_request<types.RecertificationKakao, types.RecertificationKakaoVariables>('RecertificationKakao', query, variable, { use_consumer: true, ...options });
}

export function recertificationFacebook(variable: types.RecertificationFacebookVariables, options?: GqlRequestOptions) {
  const query = 'mutation RecertificationFacebook($input: RecertificationSocialInput!) { recertificationFacebook(input: $input) { result } }';
  return gql_request<types.RecertificationFacebook, types.RecertificationFacebookVariables>('RecertificationFacebook', query, variable, { use_consumer: true, ...options });
}

export function recertificationApple(variable: types.RecertificationAppleVariables, options?: GqlRequestOptions) {
  const query = 'mutation RecertificationApple($input: RecertificationAppleInput!) { recertificationApple(input: $input) { result } }';
  return gql_request<types.RecertificationApple, types.RecertificationAppleVariables>('RecertificationApple', query, variable, { use_consumer: true, ...options });
}

export function recertificationGoogle(variable: types.RecertificationGoogleVariables, options?: GqlRequestOptions) {
  const query = 'mutation RecertificationGoogle($input: RecertificationSocialInput!) { recertificationGoogle(input: $input) { result } }';
  return gql_request<types.RecertificationGoogle, types.RecertificationGoogleVariables>('RecertificationGoogle', query, variable, { use_consumer: true, ...options });
}

export function recertificationToss(variable: types.RecertificationTossVariables, options?: GqlRequestOptions) {
  const query = 'mutation RecertificationToss($input: RecertificationTossInput!) { recertificationToss(input: $input) { result } }';
  return gql_request<types.RecertificationToss, types.RecertificationTossVariables>('RecertificationToss', query, variable, { use_consumer: true, ...options });
}

export function recertificationPassword(variable: types.RecertificationPasswordVariables, options?: GqlRequestOptions) {
  const query = 'mutation RecertificationPassword($input: RecertificationPasswordInput!) { recertificationPassword(input: $input) { result } }';
  return gql_request<types.RecertificationPassword, types.RecertificationPasswordVariables>('RecertificationPassword', query, variable, { use_consumer: true, ...options });
}

export function verifyRecertification(variable: types.VerifyRecertificationVariables, options?: GqlRequestOptions) {
  const query = 'mutation VerifyRecertification($input: VerifyRecertificationInput!) { verifyRecertification(input: $input) { result } }';
  return gql_request<types.VerifyRecertification, types.VerifyRecertificationVariables>('VerifyRecertification', query, variable, { use_consumer: true, ...options });
}

export function getUserAvailableLoginType(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserAvailableLoginType { user_account { email has_password kakao_connected apple_connected google_connected facebook_connected toss_connected } }';
  return gql_request<types.GetUserAvailableLoginType, null>('GetUserAvailableLoginType', query, variable, { use_consumer: true, ...options });
}

export function checkAvailableEmail(variable: types.CheckAvailableEmailVariables, options?: GqlRequestOptions) {
  const query = 'query CheckAvailableEmail($email: String!) { checkAvailableEmail(email: $email) }';
  return gql_request<types.CheckAvailableEmail, types.CheckAvailableEmailVariables>('CheckAvailableEmail', query, variable, { use_consumer: true, ...options });
}

export function activateInactiveUserAccount(variable?: null, options?: GqlRequestOptions) {
  const query = 'mutation ActivateInactiveUserAccount { activateInactiveUserAccount { success } }';
  return gql_request<types.ActivateInactiveUserAccount, null>('ActivateInactiveUserAccount', query, variable, { use_consumer: true, ...options });
}

export function loginMobileWithAuthentication(variable: types.LoginMobileWithAuthenticationVariables, options?: GqlRequestOptions) {
  const query = 'mutation LoginMobileWithAuthentication($input: LoginMobileWith2FAInput!) { loginMobileWithAuthentication(input: $input) { user_account { email full_name mobile_tel authenticated } } }';
  return gql_request<types.LoginMobileWithAuthentication, types.LoginMobileWithAuthenticationVariables>('LoginMobileWithAuthentication', query, variable, { use_consumer: true, ...options });
}

export function requestForResetPasswordWithAuthentication(variable: types.RequestForResetPasswordWithAuthenticationVariables, options?: GqlRequestOptions) {
  const query = 'mutation RequestForResetPasswordWithAuthentication($input: RequestForResetPasswordWithAuthenticationInput!) { requestForResetPasswordWithAuthentication(input: $input) { email token } }';
  return gql_request<types.RequestForResetPasswordWithAuthentication, types.RequestForResetPasswordWithAuthenticationVariables>('RequestForResetPasswordWithAuthentication', query, variable, { use_consumer: true, ...options });
}

export function isKakaoStudentPresentProofAlreadySubmitted(variable: types.IsKakaoStudentPresentProofAlreadySubmittedVariables, options?: GqlRequestOptions) {
  const query = 'query IsKakaoStudentPresentProofAlreadySubmitted($presentation_definition_id: String!) { isKakaoStudentPresentProofAlreadySubmitted(presentation_definition_id: $presentation_definition_id) }';
  return gql_request<types.IsKakaoStudentPresentProofAlreadySubmitted, types.IsKakaoStudentPresentProofAlreadySubmittedVariables>('IsKakaoStudentPresentProofAlreadySubmitted', query, variable, { use_consumer: true, ...options });
}

export function processUserAccountAuthenticationResponse(variable: types.ProcessUserAccountAuthenticationResponseVariables, options?: GqlRequestOptions) {
  const query = 'mutation ProcessUserAccountAuthenticationResponse($input: ProcessUserAccountAuthenticationResponseInput!) { processUserAccountAuthenticationResponse(input: $input) { full_name mobile_tel authenticate_id } }';
  return gql_request<types.ProcessUserAccountAuthenticationResponse, types.ProcessUserAccountAuthenticationResponseVariables>('ProcessUserAccountAuthenticationResponse', query, variable, { use_consumer: true, ...options });
}

export function userAccountAdultCertification(variable?: null, options?: GqlRequestOptions) {
  const query = 'query UserAccountAdultCertification { user_account_adult_certification { is_adult is_expired } }';
  return gql_request<types.UserAccountAdultCertification, null>('UserAccountAdultCertification', query, variable, { use_consumer: true, ...options });
}

export function findaccountwitauthentication(variable: types.FindaccountwitauthenticationVariables, options?: GqlRequestOptions) {
  const query = 'query Findaccountwitauthentication($authenticated_key: String!) { find_account_with_authentication(authenticated_key: $authenticated_key) { email kakao_connected apple_connected google_connected facebook_connected toss_connected has_password } }';
  return gql_request<types.Findaccountwitauthentication, types.FindaccountwitauthenticationVariables>('Findaccountwitauthentication', query, variable, { use_consumer: true, ...options });
}

export function processKmcAuthentication(variable: types.ProcessKmcAuthenticationVariables, options?: GqlRequestOptions) {
  const query = 'mutation ProcessKmcAuthentication($input: ProcessKmcAuthenticationInput!) { processKmcAuthentication(input: $input) { authenticated_key } }';
  return gql_request<types.ProcessKmcAuthentication, types.ProcessKmcAuthenticationVariables>('ProcessKmcAuthentication', query, variable, { use_consumer: true, ...options });
}

export function getUserName(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserName { user_account { full_name authenticated_name } }';
  return gql_request<types.GetUserName, null>('GetUserName', query, variable, { use_consumer: true, ...options });
}

export function checkBankAccount(variable: types.CheckBankAccountVariables, options?: GqlRequestOptions) {
  const query = 'query CheckBankAccount($bank_code: String!, $bank_account_number: String!, $bank_account_holder: String!) { checkBankAccount( bank_code: $bank_code bank_account_number: $bank_account_number bank_account_holder: $bank_account_holder ) }';
  return gql_request<types.CheckBankAccount, types.CheckBankAccountVariables>('CheckBankAccount', query, variable, { use_consumer: true, ...options });
}

export function getPaymentNotificationList(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetPaymentNotificationList { payment_notification_list { item_list { notification_type } } }';
  return gql_request<types.GetPaymentNotificationList, null>('GetPaymentNotificationList', query, variable, { use_consumer: true, ...options });
}

export function getUserOrderList(variable: types.GetUserOrderListVariables, options?: GqlRequestOptions) {
  const query = 'query GetUserOrderList($status_list: [OrderItemStatus!], $last_id: ID) { user_order_list(status_list: $status_list, last_id: $last_id) { item_list { country payment_method order_item_list { status browsing_type } } has_next } }';
  return gql_request<types.GetUserOrderList, types.GetUserOrderListVariables>('GetUserOrderList', query, variable, { use_consumer: true, ...options });
}

export function getReviewableItemListCount(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetReviewableItemListCount { reviewable_order_item_list: ux_reviewable_info_list { total_count } }';
  return gql_request<types.GetReviewableItemListCount, null>('GetReviewableItemListCount', query, variable, { use_consumer: true, ...options });
}

export function getPageInfoForWeb(variable: types.GetPageInfoForWebVariables, options?: GqlRequestOptions) {
  const query = 'fragment PageInfoPart on UxPageInfo { page_name has_next end_cursor type ui_item_list { ...UxComponentPart } } fragment UxComponentPart on UxComponent { __typename ... on UxGoodsCardItem { ...UxGoodsCardItemPart } } fragment UxGoodsCardItemPart on UxGoodsCardItem { browsing_type position type image_url webp_image_url jpeg_image_url video_url log image_ratio aid uuid product_url shop_id shop_product_no shop_name title discount_rate discount_info { image_url title color } column_count catalog_product_id goods_id one_day_delivery { ...UxCommonTextPart } has_coupon is_zpay_discount price final_price final_price_with_currency { currency decimal price_without_decimal display_currency is_currency_prefix } max_price max_price_with_currency { currency decimal price_without_decimal display_currency is_currency_prefix } is_zonly is_brand free_shipping zpay ranking sellable_status review_score display_review_count is_ad is_exclusive similar_search } fragment UxCommonTextPart on UxCommonText { text color { normal dark } html { normal dark } } query GetPageInfoForWeb( $page_id: String $category_id: Int $sorting_id: Int $age_filter_id: Int $after: String $base_shop_id: String $goods_filter_option: GoodsFilterOptionInput $filter_id_list: [ID!] $ui_property: UiPropertyInput $department_id: String ) { page_info( page_id: $page_id category_id: $category_id sorting_id: $sorting_id age_filter_id: $age_filter_id after: $after base_shop_id: $base_shop_id goods_filter_option: $goods_filter_option filter_id_list: $filter_id_list ui_property: $ui_property department_id: $department_id ) { ...PageInfoPart } }';
  return gql_request<types.GetPageInfoForWeb, types.GetPageInfoForWebVariables>('GetPageInfoForWeb', query, variable, { use_consumer: true, ...options });
}

export function logout(variable?: null, options?: GqlRequestOptions) {
  const query = 'mutation Logout { logout }';
  return gql_request<types.Logout, null>('Logout', query, variable, { use_consumer: true, ...options });
}

export function getkakaoPayMyPageBannerList(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetkakaoPayMyPageBannerList { kakao_pay_my_page_banner_list { id position type aspect_ratio is_auto_rolling update_interval item_list { id image_url landing_url position log } } }';
  return gql_request<types.GetkakaoPayMyPageBannerList, null>('GetkakaoPayMyPageBannerList', query, variable, { use_consumer: true, ...options });
}

export function getUserAccountPointInfo(variable: types.GetUserAccountPointInfoVariables, options?: GqlRequestOptions) {
  const query = 'query GetUserAccountPointInfo($expire_days: Int!) { user_account_point_info(expire_days: $expire_days) { available_point_of_use expire_point(days: $expire_days) } }';
  return gql_request<types.GetUserAccountPointInfo, types.GetUserAccountPointInfoVariables>('GetUserAccountPointInfo', query, variable, { use_consumer: true, ...options });
}

export function getZigzagNotice(variable: types.GetZigzagNoticeVariables, options?: GqlRequestOptions) {
  const query = 'query GetZigzagNotice($id: ID!) { zigzag_notice(id: $id) { id title contents contents_html category date_ymd os link is_pinned } }';
  return gql_request<types.GetZigzagNotice, types.GetZigzagNoticeVariables>('GetZigzagNotice', query, variable, { use_consumer: true, ...options });
}

export function getZigzagNoticeList(variable: types.GetZigzagNoticeListVariables, options?: GqlRequestOptions) {
  const query = 'query GetZigzagNoticeList($os: ZigzagNoticeOsType, $category: String, $date_ymd_gte: String, $date_ymd_lte: String) { zigzag_notice_list(os: $os, category: $category, date_ymd_gte: $date_ymd_gte, date_ymd_lte: $date_ymd_lte) { total_count pinned_count item_list { id title shorten_contents os date_ymd category is_pinned } } }';
  return gql_request<types.GetZigzagNoticeList, types.GetZigzagNoticeListVariables>('GetZigzagNoticeList', query, variable, { use_consumer: true, ...options });
}

export function getCatalogProductForQna(variable: types.GetCatalogProductForQnaVariables, options?: GqlRequestOptions) {
  const query = 'query GetCatalogProductForQna($catalog_product_id: ID!) { catalog_product_detail_page: pdp_base_info(catalog_product_id: $catalog_product_id) { shop { id name main_domain typical_image_url } product: catalog_product { id name product_url fulfillment_type product_image_list { url image_type } product_price { discount_price discount_amount discount_rate original_price } } } }';
  return gql_request<types.GetCatalogProductForQna, types.GetCatalogProductForQnaVariables>('GetCatalogProductForQna', query, variable, { use_consumer: true, ...options });
}

export function createCatalogProductQna(variable: types.CreateCatalogProductQnaVariables, options?: GqlRequestOptions) {
  const query = 'mutation CreateCatalogProductQna($input: CatalogQuestionInput!) { createCatalogQuestion(input: $input) }';
  return gql_request<types.CreateCatalogProductQna, types.CreateCatalogProductQnaVariables>('CreateCatalogProductQna', query, variable, { use_consumer: true, ...options });
}

export function reportProductReviewAbuse(variable: types.ReportProductReviewAbuseVariables, options?: GqlRequestOptions) {
  const query = 'mutation ReportProductReviewAbuse($input: ReportProductReviewAbuseInput!) { reportProductReviewAbuse(input: $input) }';
  return gql_request<types.ReportProductReviewAbuse, types.ReportProductReviewAbuseVariables>('ReportProductReviewAbuse', query, variable, { use_consumer: true, ...options });
}

export function reportProductReviewUserReply(variable: types.ReportProductReviewUserReplyVariables, options?: GqlRequestOptions) {
  const query = 'mutation ReportProductReviewUserReply($input: ReportProductReviewUserReplyInput!) { reportProductReviewUserReply(input: $input) }';
  return gql_request<types.ReportProductReviewUserReply, types.ReportProductReviewUserReplyVariables>('ReportProductReviewUserReply', query, variable, { use_consumer: true, ...options });
}

export function getAgeRecommendedKeywordInfo(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetAgeRecommendedKeywordInfo { age_recommended_keyword_info { title age_recommended_keyword_group_list { age recommended_keyword_list } } }';
  return gql_request<types.GetAgeRecommendedKeywordInfo, null>('GetAgeRecommendedKeywordInfo', query, variable, { use_consumer: true, ...options });
}

export function getRecommendedSearchKeywordList(variable: types.GetRecommendedSearchKeywordListVariables, options?: GqlRequestOptions) {
  const query = 'query GetRecommendedSearchKeywordList($keyword: String!) { recommended_search_keyword_list( keyword: $keyword category_limit_count: 0 event_limit_count: 0 shop_limit_count: 0 ) { item_list { type type_text keyword keyword_highlighted } } }';
  return gql_request<types.GetRecommendedSearchKeywordList, types.GetRecommendedSearchKeywordListVariables>('GetRecommendedSearchKeywordList', query, variable, { use_consumer: true, ...options });
}

export function getSearchGoodsSuggestion(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetSearchGoodsSuggestion { search_popular_keyword { recent_searches_title keywords_title keywords } }';
  return gql_request<types.GetSearchGoodsSuggestion, null>('GetSearchGoodsSuggestion', query, variable, { use_consumer: true, ...options });
}

export function getCurrencyMeta(variable?: null, options?: GqlRequestOptions) {
  const query = 'fragment CurrencyMeta on CurrencyMeta { currency decimal converted_rate display_currency is_currency_prefix } query GetCurrencyMeta { currency_meta { ...CurrencyMeta } }';
  return gql_request<types.GetCurrencyMeta, null>('GetCurrencyMeta', query, variable, { use_consumer: true, ...options });
}

export function getCurrencyMetaOfOrder(variable: types.GetCurrencyMetaOfOrderVariables, options?: GqlRequestOptions) {
  const query = 'fragment CurrencyMeta on CurrencyMeta { currency decimal converted_rate display_currency is_currency_prefix } query GetCurrencyMetaOfOrder($order_number: String!) { currency_meta_of_order(order_number: $order_number) { ...CurrencyMeta } }';
  return gql_request<types.GetCurrencyMetaOfOrder, types.GetCurrencyMetaOfOrderVariables>('GetCurrencyMetaOfOrder', query, variable, { use_consumer: true, ...options });
}

export function getUserLocale(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserLocale { user_locale { country_id currency_id language_id country { id type code label } currency { id type code label } language { id type code label } } }';
  return gql_request<types.GetUserLocale, null>('GetUserLocale', query, variable, { use_consumer: true, ...options });
}

export function updateUserLocale(variable: types.UpdateUserLocaleVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateUserLocale($input: UpdateUserLocaleInput!) { updateUserLocale(input: $input) { country_id currency_id language_id } }';
  return gql_request<types.UpdateUserLocale, types.UpdateUserLocaleVariables>('UpdateUserLocale', query, variable, { use_consumer: true, ...options });
}

export function getUserLocaleCodeList(variable: types.GetUserLocaleCodeListVariables, options?: GqlRequestOptions) {
  const query = 'query GetUserLocaleCodeList($input: LocaleCodeType) { locale_code_list(type: $input) { item_list { id type code label } total_count } }';
  return gql_request<types.GetUserLocaleCodeList, types.GetUserLocaleCodeListVariables>('GetUserLocaleCodeList', query, variable, { use_consumer: true, ...options });
}

export function requestUserAccountAuthentication(variable?: null, options?: GqlRequestOptions) {
  const query = 'mutation RequestUserAccountAuthentication { requestUserAccountAuthentication { tr_cert } }';
  return gql_request<types.RequestUserAccountAuthentication, null>('RequestUserAccountAuthentication', query, variable, { use_consumer: true, ...options });
}
